import { IToast } from '@chakra-ui/core'
import { UploadFile } from '../generated/graphql'
import algoliasearch from 'algoliasearch/lite'
import { images } from '../theme'

export const TOTAL_UNITS_SOLD = 'Total Units Sold'
export const ACTIVE_PRODUCT_PROGRESS = 'Active Product Progress'
export const ACTIVE_PRODUCTS = 'Active Products'
export const MONTHS_OF_THE_YEAR = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dev'
]

export const APP_NAME = 'TradeFed'

export const STRAPI_USER_STORAGE_KEY = 'strapi-user'

export const CLIENT_HOST = process.env.REACT_FNB_URL

export const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID || '',
  process.env.REACT_APP_ALGOLIA_API_KEY || ''
)

export const SEARCH_INDEX = `${process.env.REACT_APP_STAGE}_Products`

export const zendeskWidgetScriptUrl =
  `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_WIDGET_KEY}` || ''

export const mapsScriptUrl =
  `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places` ||
  ''

// Development
export const IS_PROD = process.env.NODE_ENV === 'production'

export const PASSWORD_REGEX_MESSAGE =
  'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1' +
  ' number and 1 special character'

export const SUCCESS_TOAST: IToast = {
  duration: 6000,
  isClosable: true,
  title: 'Success!',
  status: 'success',
  position: 'bottom-right'
}

export const ERROR_TOAST: IToast = {
  duration: 6000,
  title: 'Oops!',
  status: 'error',
  isClosable: true,
  position: 'bottom-right'
}

export const notificationsMap = {
  onboarding: {
    EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
    SIGNUP_COMPLETE: 'SIGNUP_COMPLETE',
    RESET_PASSWORD: 'RESET_PASSWORD'
  },
  seller: {
    MERCHANT_REQUEST: 'MERCHANT_REQUEST',
    MERCHANT_REQUEST_APPROVED: 'MERCHANT_REQUEST_APPROVED',
    MERCHANT_REQUEST_REJECTED: 'MERCHANT_REQUEST_REJECTED'
  },
  order: {
    STALE_WISHLIST: 'STALE_WISHLIST',
    STALE_CART: 'STALE_CART',
    ORDER_RETURNED: 'ORDER_RETURNED',
    ORDER_CREATED: 'ORDER_CREATED'
  }
}

export const DATE_FORMAT = 'DD/MM/YYYY'

export const PHONE_NUMBER_REGEX = new RegExp('^([0]{1})?([1-9]{1}[0-9]{8})$')

export const COUNTRY_CODE = '+27'

export const EMPTY_FILE: UploadFile = {
  id: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  name: '',
  hash: '',
  mime: '',
  size: 0,
  url: '',
  provider: ''
}

export const PROVINCES = [
  {
    label: 'Eastern cape',
    value: 'Eastern cape'
  },
  {
    label: 'Free state',
    value: 'Free state'
  },
  {
    label: 'Gauteng',
    value: 'Gauteng'
  },
  {
    label: 'KwaZulu natal',
    value: 'KwaZulu natal'
  },
  {
    label: 'Limpopo',
    value: 'Limpopo'
  },
  {
    label: 'Mpumalanga',
    value: 'Mpumalanga'
  },
  {
    label: 'Northern cape',
    value: 'Northern cape'
  },
  {
    label: 'North west',
    value: 'North west'
  },
  {
    label: 'Western cape',
    value: 'Western cape'
  }
]

export const QUANTITY = [
  {
    id: 1,
    label: 'Bulk',
    value: 'Bulk'
  },
  {
    id: 2,
    label: 'Single',
    value: 'Single'
  }
]

export const CATEGORIES = [
  {
    id: 1,
    name: 'Agriculture',
    categoryImage: `${images?.iconAgriculture}`
  },
  {
    id: 2,
    name: 'Mining',
    categoryImage: `${images?.iconMining}`
  },
  {
    id: 3,
    name: 'Automotive',
    categoryImage: `${images?.iconAutomotive}`
  },
  {
    id: 4,
    name: 'Electrical Equipment',
    categoryImage: `${images?.iconElectrical}`
  },
  {
    id: 5,
    name: 'Food Ingredients',
    categoryImage: `${images?.iconFood}`
  },
  {
    id: 6,
    name: 'Heavy Machinery',
    categoryImage: `${images?.iconHeavyDuty}`
  },
  {
    id: 7,
    name: 'Industrial',
    categoryImage: `${images?.iconIndustrial}`
  },
  {
    id: 8,
    name: 'Lighting',
    categoryImage: `${images?.iconLighting}`
  },
  {
    id: 9,
    name: 'Tools and Hardware',
    categoryImage: `${images?.iconTools}`
  }
]

export const INDUSTRIES = [
  { label: 'Agriculture', value: 'Agriculture' },
  { label: 'Mining', value: 'Mining' },
  { label: 'Automotive', value: 'Automotive' },
  { label: 'Electrical Equipment', value: 'Electrical Equipment' },
  { label: 'Food Ingredients', value: 'Food Ingredients' },
  { label: 'Heavy Machinery', value: 'Heavy Machinery' },
  { label: 'Industrial', value: 'Industrial' },
  { label: 'Lighting', value: 'Lighting' },
  { label: 'Tools and Hardware', value: ' Tools and Hardware' }
]

export const BEESTATUS = [
  {
    label: 'Level 1',
    value: 'Level 1'
  },
  {
    label: 'Level 2',
    value: 'Level 2'
  },
  {
    label: 'Level 3',
    value: 'Level 3'
  },
  {
    label: 'Level 4',
    value: 'Level 4'
  },
  {
    label: 'Level 5',
    value: 'Level 5'
  },
  {
    label: 'Level 6',
    value: 'Level 6'
  },
  {
    label: 'Level 7',
    value: 'Level 7'
  },
  {
    label: 'Level 8',
    value: 'Level 8'
  },
  {
    label: 'Not applicable',
    value: 'Not applicable'
  }
]

export const TURNOVER = [
  {
    label: 'Less than 1 million',
    value: 'Less than 1 million'
  },
  {
    label: '1-2 million',
    value: '1-2 million'
  },
  {
    label: '2-5 million',
    value: '2-5 million'
  },
  {
    label: '5-10 million',
    value: '5-10 million'
  },
  {
    label: 'More than 10 million',
    value: 'More than 10 million'
  }
]

export const POSITIONS = [
  {
    label: 'CEO',
    value: 'CEO'
  },
  {
    label: 'Managing Director',
    value: 'Managing Director'
  },
  {
    label: 'Financial Manager',
    value: 'Financial Manager'
  },
  {
    label: 'Financial Director',
    value: 'Financial Director'
  },
  {
    label: 'Procurement Manager',
    value: 'Procurement Manager'
  },
  {
    label: 'Sales Manager',
    value: 'Sales Manager'
  },
  {
    label: 'Other',
    value: 'Other'
  }
]

export const PARTS_FILTER_OPTIONS = [
  { label: 'Alternators', value: 'ALTERNATORS' },
  { label: 'Bearings', value: 'BEARING' },
  { label: 'Belts', value: 'BELT' },
  { label: 'Body Parts', value: 'BODY PARTS' },
  { label: 'Brakes', value: 'BRAKE' },
  { label: 'Calipers', value: 'CALIPER' },
  { label: 'Clutches', value: 'CLUTCH' },
  { label: 'Cylinders', value: 'CYLINDER' },
  { label: 'Engines', value: 'ENGINE' },
  { label: 'Filters', value: 'FILTER' },
  { label: 'Gears', value: 'GEAR' },
  { label: 'Joints', value: 'JOINTS' },
  { label: 'Mountings', value: 'MOUNTINGS' },
  { label: 'Oil', value: 'OIL' },
  { label: 'Pumps', value: 'PUMPS' },
  { label: 'Plugs', value: 'PLUGS' },
  { label: 'Racks', value: 'RACK' },
  { label: 'Radiators', value: 'RADIATOR' },
  { label: 'Shocks', value: 'SHOCK' },
  { label: 'Shafts', value: 'SHAFT' },
  { label: 'Starters', value: 'STARTERS' },
  { label: 'Valves', value: 'VALVES' },
  { label: 'Wheels', value: 'WHEEL' },
  { label: 'Wipers', value: 'WIPER' }
]

export const SUB_CATEGORIES_OPTION = [
  { label: 'Kit', value: 'KIT' },
  { label: 'Plate', value: 'PLATE' },
  { label: 'Cable', value: 'CABLE' },
  { label: 'Cylinder', value: 'CYLINDER' },
  { label: 'Bearing', value: 'BEARING' }
]

export const BRANDS_FILTER_OPTIONS = [
  { label: 'Generic', value: 'Generic' },

  { label: 'Air Filtrado', value: 'AIR FILTRADO' },
  { label: 'Auseto', value: 'AUSETO' },
  { label: 'Auto Part', value: 'AUTO PART' },
  { label: 'Autobahn', value: 'AUTOBAHN' },
  { label: 'Autofriction', value: 'AUTOFRICTION' },
  { label: 'AWS', value: 'AWS' },
  { label: 'Denckermann', value: 'DENCKERMANN' },
  { label: 'Deselra', value: 'DESELRA' },
  { label: 'Dtek', value: 'DTEK' },
  { label: 'Dynomical', value: 'DYNOMICAL' },
  { label: 'Duratech', value: 'DURATECH' },
  { label: 'Dunlop', value: 'DUNLOP' },
  { label: 'Ferodo', value: 'FERODO' },
  { label: 'Genuine Parts', value: 'GENUINE PARTS' },
  { label: 'Gud', value: 'GUD' },
  { label: 'Klauten', value: 'KLAUTEN' },
  { label: 'KrAtex', value: 'KRATEX' },
  { label: 'Lyon', value: 'LYON' },
  { label: 'NWP', value: 'NWP' },
  { label: 'Omni 5', value: 'OMNI 5' },
  { label: 'Sachte', value: 'SACHTE' },
  { label: 'Shield Car Care', value: 'SHIELD' },
  { label: 'Trojan', value: 'TROJAN' },
  { label: 'Vega', value: 'VEGA' }
]

export { NAV_ITEMS } from './navItems'

export const VIEW_MORE_OFFSET = 40
