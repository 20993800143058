import * as React from 'react'
import { Input } from '@chakra-ui/core'
import { connectSearchBox } from 'react-instantsearch-dom'

type searchBoxProps = {
  currentRefinement: string
  showHits: boolean
  refine: (value: string) => void
  setShowHits: (val: boolean) => void
  theme?: string
}

const themes = {
  dark: {
    color: 'white',
    paddingLeft: '40px',
    borderColor: 'transparent',
    bg: 'accent.20',
    borderRadius: '0',
    _focus: {
      outline: 'none',
      padding: '12px 2px',
      border: '1px solid transparent',
      borderRadius: 0,
      borderBottom: '1px solid #575756'
    },
    _hover: {
      outline: 'none',
      padding: '12px 2px',
      border: '1px solid transparent',
      borderRadius: 0,
      borderBottom: '1px solid #575756'
    }
  },
  light: {
    dark: {
      color: 'black',
      paddingLeft: '40px',
      borderColor: 'transparent',
      borderRadius: '0',
      _focus: {
        outline: 'none',
        border: '1px solid transparent',
        borderRadius: 0
      },
      _hover: {
        outline: 'none',
        border: '1px solid transparent',
        borderRadius: 0
      }
    }
  }
}

const SearchBox: React.FC<searchBoxProps> = ({ setShowHits, refine, theme = 'dark' }) => {
  const [searchTerm, setSearchTerm] = React.useState('')
  return (
    <form
      noValidate
      action=""
      role="search"
      onSubmit={(event) => {
        event.preventDefault()
      }}
    >
      <Input
        type="search"
        placeholder="Search products..."
        name="search"
        fontSize={16}
        value={searchTerm}
        {...(theme === 'dark' ? themes.dark : themes.light)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          event.preventDefault()

          if (event.target.value.length > 2) {
            setShowHits(true)
            refine(event.currentTarget.value)
          } else {
            setShowHits(false)
          }
          setSearchTerm(event.target.value)
        }}
        autoComplete="off"
      />
    </form>
  )
}

const CustomSearchBox = connectSearchBox(SearchBox)

export default CustomSearchBox
