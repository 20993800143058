import React from 'react'
import { Flex } from '@chakra-ui/core'

import { theme } from '../../theme'
import './spinner.css'

const Spinner = () => {
  return (
    <Flex
      className="spinner-container"
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        className="loading-spinner"
        flexDirection="row"
        width="64px"
        height="64px"
        border="8px solid"
        borderRadius="50%"
        borderColor={`${theme.colors.blueText} transparent ${theme.colors.accent[700]} transparent`}
      />
    </Flex>
  )
}

export default Spinner
