import React from 'react'
import ReactDOM from 'react-dom'
import mixpanel from 'mixpanel-browser'

import { Helmet } from 'react-helmet'
import { css, Global } from '@emotion/core'
import { ApolloProvider } from '@apollo/react-hooks'
import { CSSReset, ThemeProvider } from '@chakra-ui/core'

import App from './App'
import client from './client'
import * as serviceWorker from './serviceWorker'

import { theme } from './theme'
import { APP_NAME } from './constants'
import { AppProvider, AuthProvider } from './context'

import 'react-dates/initialize'

import * as Sentry from '@sentry/react'

const getEnvironment = () => {
  if (process.env.REACT_APP_STAGE === 'prod') {
    return 'production'
  }
  if (process.env.REACT_APP_STAGE === 'uat') {
    return 'uat'
  }
  return 'development'
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: getEnvironment(),
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

mixpanel.init(`${process.env.REACT_APP_MIXPANEL_KEY}`, {
  debug: process.env.REACT_APP_STAGE === 'dev',
  ignore_dnt: true
})

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Helmet titleTemplate={`${APP_NAME} | %s`} />
          <Helmet>
            {process.env.REACT_APP_STAGE === 'prod' ? (
              <script
                type="text/javascript"
                src="https://secure1.ecentricpaymentgateway.co.za/hpp/API/js"
              ></script>
            ) : (
              <script
                type="text/javascript"
                src="https://sandbox.ecentric.co.za/HPP/API/js"
              ></script>
            )}
          </Helmet>
          <AppProvider>
            <CSSReset />
            <Global
              styles={css`
                * {
                  font-family: ${theme.fonts.body};
                }
                body {
                  background: ${theme.colors.background};
                }
              `}
            />
            <App />
          </AppProvider>
        </AuthProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
