/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import styled from '@emotion/styled'

import { get } from 'lodash'
import { motion } from 'framer-motion'
import { ApolloError } from 'apollo-boost'
import { Search, ShoppingCart, Star } from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import { Configure, Hits, InstantSearch, Stats } from 'react-instantsearch-dom'
import { Flex, Grid, Image, Text, useToast } from '@chakra-ui/core'
import { color, ColorProps, space, SpaceProps } from 'styled-system'
import { RouteComponentProps, withRouter, useHistory } from 'react-router'

import { images, theme } from '../../theme'
import { useAppContext } from '../../context/AppProvider'
import { useAuthContext } from '../../context/AuthProvider'
import { SEARCH_INDEX, searchClient, ERROR_TOAST } from '../../constants'
import {
  useAddProductToCartMutation,
  useFetchUsersCartQuery,
  useFetchUsersWhishlistQuery
} from '../../generated/graphql'
import CustomSearchBox from '../SearchBox'
import SideBarButton from '../SideBar/SideBarButton'

import './index.css'
import { Link } from 'react-router-dom'

type HeaderProps = RouteComponentProps &
  ColorProps & {
    color?: string
    size?: number
    id?: string
    open?: boolean
    getLoggedInUser?: () => { name?: string; id: string }
  }

type HeaderContProps = SpaceProps &
  ColorProps & {
    color?: string
    open?: boolean
  } & {
    height: number
  }

type CounterProps = {
  count: number
  color: string
}

type CartProps = {
  productToAdd: string
  quantity: number
}

const NotificationsCounter: FC<CounterProps> = ({ count, color }) => {
  return (
    <Flex
      flexDirection="column"
      backgroundColor={color}
      height="20px"
      width="20px"
      color="#fff"
      textAlign="center"
      justify="center"
      align="center"
      borderRadius="50%"
      fontSize="12px"
      position="absolute"
      top="-10px"
      right="-10px"
    >
      {count > 9 ? '9+' : count}
    </Flex>
  )
}

const HeaderCont = styled(motion.div)<HeaderContProps>`
  ${space};
  ${color};
  top: 0;
  right: 0;
  height: ${(props) => props.height}px;
  z-index: 999;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  box-shadow: 0 1px 1px 0 ${theme.colors.brand[700]};
  left: ${(props) => (props.open ? '250px' : 0)};
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

export const ResultItem = styled.div`
  border-radius: 0.1em;
  color: #555555;
  cursor: pointer;
  list-style: none;
  padding: 0.6em 1em;
  font-size: 13px;
  margin-top: 0.5em;
  display: grid;
  width: 100%;
  grid-template-columns: 15% 65% 20%;

  &:hover {
    background: #a7a6a633;
    color: #a267ac;
  }
`

const Header: FC<HeaderProps> = ({ ...rest }) => {
  const history = useHistory()
  const ref = useRef(null)
  const toast = useToast()
  const { drawerOpen, toggleDrawer } = useAppContext()
  const { user, isAuthenticated } = useAuthContext()

  const [cartItems, setCartItems] = useState<CartProps[]>([])
  const [showHits, setShowHits] = useState<boolean>(false)
  const [searchProducts, setSearchProducts] = useState<boolean>(false)

  const { data: userCart, refetch: refetchCart } = useFetchUsersCartQuery({
    variables: {
      offset: 0,
      limit: 1
    },
    onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST })
  })

  const { data: userWishlist, refetch: refetchWishlist } = useFetchUsersWhishlistQuery({
    onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST })
  })

  const [addProductToCart] = useAddProductToCartMutation({
    onError: (err: ApolloError) => toast({ description: err.message, ...ERROR_TOAST })
  })

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const isMobile = useMediaQuery({ query: '(max-width: 70em)' })
  const isSellerApproved = user?.isSeller === 'approved'

  const numberOfItems = get(userCart, 'findCart.payload.totalItemsCount') || 0

  const hasProducts = numberOfItems > 0

  const wishlist = get(userWishlist, 'findOneWishlist.payload.products', null) || []

  const wishlistLength = wishlist?.length
  const showWishlistLength = wishlistLength > 0

  const handleWishlistIconClicked = useCallback(() => {
    history.push('/wishlist')
  }, [history])

  const handleCartIconClicked = useCallback(() => {
    history.push('/cart')
  }, [history])

  const handleMyaccount = useCallback(() => {
    history.push('/profile')
  }, [history])

  const handleBecomeSeller = useCallback(() => {
    if (isSellerApproved) {
      history.push('/product-management')
    } else {
      history.push('/apply-seller')
    }
  }, [history, isSellerApproved])

  const handleLoginClicked = useCallback(() => {
    history.push('/login')
  }, [history])

  const handleLogoClicked = useCallback(() => {
    history.push('/')
  }, [history])

  const navigateToProduct = useCallback(
    (id: any) => {
      const modifiedId = id?.toLowerCase()
      const encodedCode = encodeURIComponent(modifiedId)
      history.push(`/product/${encodedCode}`)
      setShowHits(false)
      setSearchProducts(false)
    },
    [history]
  )

  const Result: FC = useCallback(
    (response: any) => {
      const { hit } = response

      if (hit?.isClientWebManagedProduct === true || hit?.isClientWebManagedProduct === '1') {
        return null
      }
      return (
        <ResultItem className="item" onClick={() => navigateToProduct(hit.uniqueIdentifier)}>
          <Grid>
            <Image
              mr={3}
              rounded="s"
              width="35px"
              borderRadius="50%"
              height="auto"
              src={hit?.coverImage?.url}
            />
          </Grid>
          <Grid>
            <strong>{hit.name}</strong>
          </Grid>
          <Grid>
            R{' '}
            {parseFloat(hit.tradeFedCost)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </Grid>
        </ResultItem>
      )
    },
    [navigateToProduct]
  )

  const algoliaClient = {
    ...searchClient,
    search(requests: any) {
      if (requests.every(({ params }: any) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: ''
          }))
        })
      }

      return searchClient.search(requests)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setShowHits(false)
        setSearchProducts(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  useEffect(() => {
    refetchCart()
    refetchWishlist()
  }, [refetchCart, refetchWishlist])

  useEffect(() => {
    if (user && !user.cart) {
      // Fetch cart items for logged in user from server
      // and update cart
      const items = JSON.parse(localStorage.getItem('cartItems') || '[]')
      setCartItems(items)

      if (items.length > 0) {
        Promise.all(cartItems.map(async (item: any) => await addProductToCart(item)))
      }
    } else {
      // Get cart items from local storage and update state
      const items = JSON.parse(localStorage.getItem('cartItems') || '[]')
      setCartItems(items)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <HeaderCont pr={4} pl="1rem" {...rest} height={isMobile ? 80 : 95}>
      <InstantSearch indexName={SEARCH_INDEX} searchClient={algoliaClient}>
        <SideBarButton color="white" open={drawerOpen} onClick={toggleDrawer} />
        <Flex
          width={isTabletOrMobile ? '50%' : '40%'}
          align="center"
          justifyContent={'flex-start'}
          pl={isTabletOrMobile ? 3 : 5}
        >
          <Image
            hidden={drawerOpen ? true : false}
            cursor="pointer"
            onClick={handleLogoClicked}
            mr={5}
            pb={2}
            width={isTabletOrMobile ? '30px' : '200px'}
            src={isTabletOrMobile ? images['TFMobileLogo'] : images['TFInverseWhite']}
          />
        </Flex>
        <Flex display={isTabletOrMobile ? 'none' : 'flex'} width={isTabletOrMobile ? 0 : '55%'}>
          <Flex width="40%" alignItems="center" justifyContent="space-between" px={5}>
            {user?.isBusiness === true && (
              <Text
                color="white"
                fontSize="14px"
                cursor="pointer"
                onClick={handleBecomeSeller}
                pr={4}
              >
                {isSellerApproved ? '' : 'Become a Seller'}
                {/* this will e re-implemented when the client asks for product management page back */}
                {/* {isSellerApproved ? 'Product Management' : 'Become a Seller'} */}
              </Text>
            )}
            {isAuthenticated ? (
              <Link to="/create-bulk-cart">
                <Text color="white" fontSize="14px" cursor="pointer">
                  Bulk Order
                </Text>
              </Link>
            ) : null}
            {isAuthenticated ? (
              <Text color="white" fontSize="14px" cursor="pointer" onClick={handleMyaccount}>
                My Account
              </Text>
            ) : (
              <Text
                color="white"
                fontSize="14px"
                cursor="pointer"
                onClick={handleLoginClicked}
                pr={4}
              >
                Login / Sign Up
              </Text>
            )}
          </Flex>
          <Flex width="65%" mr={4}>
            <main ref={ref}>
              <CustomSearchBox setShowHits={setShowHits} showHits={showHits} />
              {showHits && (
                <div
                  style={{
                    position: 'absolute',
                    zIndex: 10,
                    backgroundColor: 'white',
                    width: '100%',
                    marginTop: '1rem',
                    maxHeight: '400px',
                    overflow: 'auto'
                  }}
                >
                  <Configure hitsPerPage={300} />
                  <Stats />

                  <Hits hitComponent={Result} />
                </div>
              )}
            </main>
          </Flex>
        </Flex>
        {isTabletOrMobile && (
          <Flex flexDirection="column" cursor="pointer" mr={5} position="relative" ml="auto">
            {!searchProducts && <Search color="white" onClick={() => setSearchProducts(true)} />}
            {searchProducts && (
              <Flex width="52%" mr={4}>
                <main ref={ref}>
                  <CustomSearchBox setShowHits={setShowHits} showHits={showHits} />
                  {showHits && (
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: 10,
                        backgroundColor: 'white',
                        width: '100%',
                        marginTop: '1rem',
                        overflow: 'auto'
                      }}
                    >
                      <Configure hitsPerPage={8} />
                      <Stats />
                      <Hits hitComponent={Result} />
                    </div>
                  )}
                </main>
              </Flex>
            )}
          </Flex>
        )}
        <Flex flexDirection="column" cursor="pointer" mr={5} position="relative" ml={1}>
          {wishlist && showWishlistLength && isAuthenticated ? (
            <NotificationsCounter count={wishlistLength} color={theme.colors.brand[500]} />
          ) : null}
          <Star color="white" onClick={handleWishlistIconClicked} />
        </Flex>
        <Flex flexDirection="column" cursor="pointer" mr={5} position="relative" ml={1}>
          {hasProducts && isAuthenticated ? (
            <NotificationsCounter count={numberOfItems} color={theme.colors.brand[500]} />
          ) : null}
          <ShoppingCart color="white" onClick={handleCartIconClicked} />
        </Flex>
      </InstantSearch>
    </HeaderCont>
  )
}

export default withRouter(Header)

Header.defaultProps = {
  bg: 'brand.700'
}
